import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, Hash, Calculator, FileJson, FileText, PlayCircle, Phone, Building2, Search } from "lucide-react";

const Dashboard = () => {
  const tools = [
    { name: "時間戳記工具", icon: <Clock className="h-8 w-8" />, path: "/timestamp" },
    { name: "TX Hash 解析", icon: <Hash className="h-8 w-8" />, path: "/tx-decoder" },
    { name: "智慧過河遊戲", icon: <PlayCircle className="h-8 w-8" />, path: "/river-crossing-game" },
    { name: "河內塔遊戲", icon: <Building2 className="h-8 w-8" />, path: "/hanoi-tower" },
    { name: "轉換工具", icon: <Calculator className="h-8 w-8" />, path: "/convert-tool" },
    { name: "JSON 結構檢查", icon: <FileJson className="h-8 w-8" />, path: "/json-checker" },
    { name: "文本差異比較", icon: <FileText className="h-8 w-8" />, path: "/diff-checker" },
    { name: "區塊鏈呼叫器", icon: <Phone className="h-8 w-8" />, path: "/blockchain-caller" },
    { name: "訂單檢查器", icon: <Search className="h-8 w-8" />, path: "/order-checker" }, // 新增这行
  ];

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">區塊鏈工具集</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tools.map((tool, index) => (
          <Link 
            key={index} 
            to={tool.path}
            className="block border rounded-lg p-4 shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105"
          >
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-semibold">{tool.name}</h2>
              {tool.icon}
            </div>
            <span className="text-blue-500 hover:underline">前往工具</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;