import React, { useState, useEffect } from 'react';

const HanoiTower = () => {
  const [disks, setDisks] = useState(3);
  const [towers, setTowers] = useState([[], [], []]);
  const [selectedDisk, setSelectedDisk] = useState(null);
  const [moves, setMoves] = useState(0);

  useEffect(() => {
    resetGame();
  }, [disks]);

  const resetGame = () => {
    const newTowers = [
      Array.from({ length: disks }, (_, i) => disks - i),
      [],
      []
    ];
    setTowers(newTowers);
    setMoves(0);
    setSelectedDisk(null);
  };

  const handleDiskClick = (towerIndex, diskIndex) => {
    if (selectedDisk === null) {
      if (diskIndex === towers[towerIndex].length - 1) {
        setSelectedDisk({ towerIndex, diskIndex });
      }
    } else {
      const sourceTower = towers[selectedDisk.towerIndex];
      const targetTower = towers[towerIndex];
      
      if (towerIndex !== selectedDisk.towerIndex) {
        if (targetTower.length === 0 || sourceTower[sourceTower.length - 1] < targetTower[targetTower.length - 1]) {
          const newTowers = towers.map(tower => [...tower]);
          const disk = newTowers[selectedDisk.towerIndex].pop();
          newTowers[towerIndex].push(disk);
          setTowers(newTowers);
          setMoves(moves + 1);
          
          if (newTowers[2].length === disks) {
            alert(`恭喜你完成了遊戲！總共移動了 ${moves + 1} 次。`);
          }
        }
      }
      setSelectedDisk(null);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">河內塔遊戲</h1>
      <div className="mb-4">
        <label className="mr-2">圓盤數量：</label>
        <input
          type="number"
          value={disks}
          onChange={(e) => setDisks(Math.max(1, Math.min(8, parseInt(e.target.value) || 1)))}
          className="border rounded px-2 py-1"
          min="1"
          max="8"
        />
        <button
          onClick={resetGame}
          className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          重置遊戲
        </button>
      </div>
      <div className="flex justify-around mt-8">
        {towers.map((tower, towerIndex) => (
          <div 
            key={towerIndex} 
            className="flex flex-col-reverse items-center"
            onClick={() => handleDiskClick(towerIndex, tower.length - 1)}
          >
            {tower.map((disk, diskIndex) => (
              <div
                key={disk}
                className={`h-8 rounded-full cursor-pointer ${
                  selectedDisk && selectedDisk.towerIndex === towerIndex && selectedDisk.diskIndex === diskIndex
                    ? 'bg-yellow-500'
                    : 'bg-blue-500'
                }`}
                style={{ width: `${disk * 30}px` }}
              ></div>
            ))}
            <div className="w-2 h-64 bg-gray-400 mt-2"></div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <p className="text-xl">移動次數: {moves}</p>
      </div>
    </div>
  );
};

export default HanoiTower;
