import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

const ConvertTool = () => {
  const [weiValue, setWeiValue] = useState('');
  const [ethValue, setEthValue] = useState('');
  const [hexValue, setHexValue] = useState('');
  const [decimalValue, setDecimalValue] = useState('');

  const convertWeiToEth = () => {
    try {
      const ethAmount = ethers.formatEther(weiValue);
      setEthValue(ethAmount);
    } catch (error) {
      setEthValue('Invalid input');
    }
  };

  const convertEthToWei = () => {
    try {
      const weiAmount = ethers.parseEther(ethValue).toString();
      setWeiValue(weiAmount);
    } catch (error) {
      setWeiValue('Invalid input');
    }
  };

  const convertHexToDecimal = () => {
    try {
      const decimal = parseInt(hexValue, 16);
      setDecimalValue(decimal.toString());
    } catch (error) {
      setDecimalValue('Invalid input');
    }
  };

  const convertDecimalToHex = () => {
    try {
      const hex = parseInt(decimalValue).toString(16);
      setHexValue('0x' + hex);
    } catch (error) {
      setHexValue('Invalid input');
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">轉換工具</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
        <div className="border rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold mb-2">Wei 和 ETH 轉換</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="輸入 Wei 數量"
              value={weiValue}
              onChange={(e) => setWeiValue(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <button onClick={convertWeiToEth} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              轉換為 ETH
            </button>
          </div>
          <div>
            <input
              type="text"
              placeholder="輸入 ETH 數量"
              value={ethValue}
              onChange={(e) => setEthValue(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <button onClick={convertEthToWei} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              轉換為 Wei
            </button>
          </div>
        </div>

        <div className="border rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold mb-2">十六進制和十進制轉換</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="輸入十六進制數值"
              value={hexValue}
              onChange={(e) => setHexValue(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <button onClick={convertHexToDecimal} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              轉換為十進制
            </button>
          </div>
          <div>
            <input
              type="text"
              placeholder="輸入十進制數值"
              value={decimalValue}
              onChange={(e) => setDecimalValue(e.target.value)}
              className="w-full p-2 border rounded mb-2"
            />
            <button onClick={convertDecimalToHex} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
              轉換為十六進制
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertTool;