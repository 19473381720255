import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const JsonChecker = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [result, setResult] = useState('');

  const checkJson = () => {
    try {
      JSON.parse(jsonInput);
      setResult('JSON 結構有效');
    } catch (e) {
      setResult(`JSON 結構無效: ${e.message}`);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">JSON 結構檢查工具</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="mt-4">
        <textarea
          className="w-full h-64 p-2 border rounded"
          value={jsonInput}
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder="在此輸入 JSON..."
        ></textarea>
        <button 
          onClick={checkJson}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          檢查 JSON
        </button>
        {result && (
          <div className={`mt-4 p-4 rounded ${result.includes('有效') ? 'bg-green-100' : 'bg-red-100'}`}>
            {result}
          </div>
        )}
      </div>
    </div>
  );
};

export default JsonChecker;