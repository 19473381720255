import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

const INITIAL_GAME_STATE = {
  leftBank: ['fox', 'chicken', 'corn'],
  rightBank: [],
  boat: [],
  boatPosition: 'left',
  steps: 0,
  failCount: 0,
  attemptTime: 100,
  gameTime: 300,
  message: '',
  isGameOver: false,
  showStartPopup: true,
  showEndPopup: false,
  ratingCode: '',
  score: 0,
  showHint: false,
};

const RiverCrossingGame = () => {
  const [gameState, setGameState] = useState(INITIAL_GAME_STATE);
  const [showRules, setShowRules] = useState(false);

  const attemptTimerRef = useRef(null);
  const gameTimerRef = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(attemptTimerRef.current);
      clearInterval(gameTimerRef.current);
    };
  }, []);

  const initGame = useCallback(() => {
    setGameState(prevState => ({
      ...INITIAL_GAME_STATE,
      failCount: prevState.failCount,
      gameTime: prevState.gameTime,
      showStartPopup: false,
    }));
    startTimers();
  }, []);

  const startTimers = useCallback(() => {
    clearInterval(attemptTimerRef.current);
    clearInterval(gameTimerRef.current);

    attemptTimerRef.current = setInterval(() => {
      setGameState(prevState => {
        if (prevState.attemptTime <= 1) {
          clearInterval(attemptTimerRef.current);
          return endRound('時間到！本輪失敗。', prevState);
        }
        return { ...prevState, attemptTime: prevState.attemptTime - 1 };
      });
    }, 1000);

    gameTimerRef.current = setInterval(() => {
      setGameState(prevState => {
        if (prevState.gameTime <= 1) {
          clearInterval(gameTimerRef.current);
          return endGame('遊戲時間結束！', false, prevState);
        }
        return { ...prevState, gameTime: prevState.gameTime - 1 };
      });
    }, 1000);
  }, []);

  const toggleItemOnBoat = useCallback((item) => {
    setGameState(prevState => {
      const currentBank = prevState.boatPosition === 'left' ? 'leftBank' : 'rightBank';
      const updatedState = { ...prevState };

      if (updatedState.boat.includes(item)) {
        updatedState.boat = updatedState.boat.filter(i => i !== item);
        updatedState[currentBank] = [...updatedState[currentBank], item];
      } else if (updatedState[currentBank].includes(item) && updatedState.boat.length === 0) {
        updatedState[currentBank] = updatedState[currentBank].filter(i => i !== item);
        updatedState.boat = [item];
      }

      return checkGameState(updatedState);
    });
  }, []);

  const moveBoat = useCallback((direction) => {
    setGameState(prevState => {
      const updatedState = {
        ...prevState,
        boatPosition: direction,
        steps: prevState.steps + 1,
      };
      return checkGameState(updatedState);
    });
  }, []);

  const checkGameState = useCallback((state) => {
    const { leftBank, rightBank, boat, boatPosition } = state;
    
    if (isUnsafeState(leftBank, boatPosition !== 'left') || 
        isUnsafeState(rightBank, boatPosition !== 'right')) {
      return endRound('遊戲失敗！狐狸吃了雞，或雞吃了玉米。', state);
    } else if (rightBank.length + (boatPosition === 'right' ? boat.length : 0) === 3) {
      return endGame('恭喜！你成功把所有物品安全運到對岸。', true, state);
    }
    
    return state;
  }, []);

  const isUnsafeState = useCallback((items, boatAway) => {
    return boatAway && (
      (items.includes('fox') && items.includes('chicken') && !items.includes('corn')) ||
      (items.includes('chicken') && items.includes('corn') && !items.includes('fox'))
    );
  }, []);

  const endRound = useCallback((message, state) => {
    clearInterval(attemptTimerRef.current);
    const updatedState = {
      ...state,
      message,
      failCount: state.failCount + 1,
      showEndPopup: true,
      attemptTime: 100, // 重置單輪時間
      leftBank: ['fox', 'chicken', 'corn'],
      rightBank: [],
      boat: [],
      boatPosition: 'left',
      steps: 0,
    };

    if (updatedState.failCount >= 3 || updatedState.gameTime <= 0) {
      return endGame('遊戲結束！失敗次數達到3次或時間耗盡。', false, updatedState);
    }

    return updatedState;
  }, []);

  const calculateScore = useCallback((steps, roundTime, totalTime, failCount) => {
    let score = 0;
    
    // 步數評分（越少越好）
    if (steps <= 7) score += 40;
    else if (steps <= 10) score += 30;
    else if (steps <= 13) score += 20;
    else score += 10;
    
    // 本輪用時評分（越短越好）
    const usedTime = 100 - roundTime;
    if (usedTime <= 30) score += 30;
    else if (usedTime <= 60) score += 20;
    else if (usedTime <= 90) score += 10;
    
    // 總用時評分（越短越好）
    const totalUsedTime = 300 - totalTime;
    if (totalUsedTime <= 100) score += 20;
    else if (totalUsedTime <= 200) score += 15;
    else if (totalUsedTime <= 250) score += 10;
    else score += 5;
    
    // 失敗次數評分（越少越好）
    if (failCount === 0) score += 10;
    else if (failCount === 1) score += 5;
    
    return score;
  }, []);

  const getRatingCode = useCallback((score) => {
    const ratingCodes = [
      'HONEST', 'FAIR55', 'GOODJB', 'EFFORT', 'DECENT', 
      'COOL33', 'BRAVO2', 'AWESOME', 'SUPER1'
    ];
    const index = Math.min(Math.floor(score / 12), ratingCodes.length - 1);
    return ratingCodes[index];
  }, []);

  const endGame = useCallback((message, success, state) => {
    clearInterval(attemptTimerRef.current);
    clearInterval(gameTimerRef.current);

    const updatedState = {
      ...state,
      message,
      isGameOver: true,
      showEndPopup: true,
    };

    if (success) {
      const score = calculateScore(state.steps, state.attemptTime, state.gameTime, state.failCount);
      const ratingCode = getRatingCode(score);
      updatedState.ratingCode = ratingCode;
      updatedState.score = score;
    }

    return updatedState;
  }, [calculateScore, getRatingCode]);

  const restartGame = useCallback(() => {
    setGameState(prevState => ({
      ...INITIAL_GAME_STATE,
      failCount: prevState.isGameOver ? 0 : prevState.failCount,
      gameTime: prevState.isGameOver ? 300 : prevState.gameTime,
      showStartPopup: false,
    }));
    startTimers();
  }, [startTimers]);

  const toggleHint = useCallback(() => {
    setGameState(prevState => ({ ...prevState, showHint: !prevState.showHint }));
  }, []);

  const renderItem = useCallback((item, location) => {
    const emoji = { fox: '🦊', chicken: '🐔', corn: '🌽' }[item];
    return (
      <span
        key={`${item}-${location}`}
        onClick={() => toggleItemOnBoat(item)}
        className="text-4xl cursor-pointer m-2 transition-transform duration-300 hover:scale-125"
        title={`點擊將${item === 'fox' ? '狐狸' : item === 'chicken' ? '雞' : '玉米'}${location === 'boat' ? '移出' : '移入'}船`}
      >
        {emoji}
      </span>
    );
  }, [toggleItemOnBoat]);

  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">智慧過河遊戲 - 進階版</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回儀表板
      </Link>
      
      <button onClick={() => setShowRules(!showRules)} className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 mb-4 ml-4">
        {showRules ? '隱藏規則' : '顯示規則'}
      </button>

      {showRules && (
        <div className="bg-yellow-100 p-4 rounded-lg mb-4">
          <h3 className="font-bold mb-2">遊戲規則：</h3>
          <ul className="list-disc list-inside">
            <li>你需要將狐狸、雞和玉米安全運送到河對岸</li>
            <li>船一次只能載一個物品</li>
            <li>不能讓狐狸和雞單獨在一起，否則狐狸會吃掉雞</li>
            <li>不能讓雞和玉米單獨在一起，否則雞會吃掉玉米</li>
            <li>每輪遊戲時間為100秒，總遊戲時間為300秒</li>
            <li>失敗3次遊戲結束</li>
          </ul>
        </div>
      )}
      
      <div className="flex justify-between items-center my-8 relative h-64 bg-blue-400 rounded-lg overflow-hidden">
        <div className="w-1/3 h-full bg-green-300 rounded-lg p-4 flex flex-wrap content-start justify-center">
          {gameState.leftBank.map(item => renderItem(item, 'left'))}
        </div>
        <div className={`absolute bottom-0 transition-all duration-1000 ease-in-out ${gameState.boatPosition === 'left' ? 'left-1/3' : 'left-2/3'} transform -translate-x-1/2 animate-boat-float`}>
          <span className="text-5xl">⛵️</span>
          <div className="flex">{gameState.boat.map(item => renderItem(item, 'boat'))}</div>
        </div>
        <div className="w-1/3 h-full bg-green-300 rounded-lg p-4 flex flex-wrap content-start justify-center">
          {gameState.rightBank.map(item => renderItem(item, 'right'))}
        </div>
      </div>

      <div className="flex justify-center space-x-4 mb-4">
        <button 
          onClick={() => moveBoat('left')} 
          disabled={gameState.boatPosition === 'left' || gameState.isGameOver}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          ← 向左划
        </button>
        <button 
          onClick={() => moveBoat('right')} 
          disabled={gameState.boatPosition === 'right' || gameState.isGameOver}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          向右划 →
        </button>
        <button
          onClick={toggleHint}
          className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
        >
          {gameState.showHint ? '隱藏提示' : '顯示提示'}
        </button>
      </div>

      {gameState.showHint && (
        <div className="bg-purple-100 p-4 rounded-lg mb-4">
          <h3 className="font-bold mb-2">提示：</h3>
          <p>考慮先將雞運過去，然後...</p>
        </div>
      )}

      <div className="text-center text-lg font-semibold mb-2">{gameState.message}</div>
      <div className="grid grid-cols-2 gap-4 text-center">
        <div className="bg-gray-100 p-2 rounded">本輪剩餘時間: {gameState.attemptTime} 秒</div>
        <div className="bg-gray-100 p-2 rounded">遊戲總剩餘時間: {gameState.gameTime} 秒</div>
        <div className="bg-gray-100 p-2 rounded">步數: {gameState.steps}</div>
        <div className="bg-gray-100 p-2 rounded">失敗次數: {gameState.failCount}</div>
      </div>

      {gameState.showStartPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg text-center max-w-md">
            <h2 className="text-2xl font-bold mb-4">準備開始遊戲</h2>
            <p className="mb-2">點擊開始按鈕來挑戰你的智慧！</p>
            <p className="mb-2">每輪遊戲時間為100秒，總遊戲時間為300秒</p>
            <p className="mb-4">失敗3次遊戲結束</p>
            <button 
              onClick={initGame}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              開始遊戲
            </button>
          </div>
        </div>
      )}

      {gameState.showEndPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg text-center max-w-md">
            <h2 className="text-2xl font-bold mb-4">{gameState.message}</h2>
            {gameState.ratingCode && (
              <div className="my-4 p-4 bg-blue-100 rounded-lg">
                <div className="text-2xl font-bold text-green-600 mb-2">
                  恭喜完成！你的評級代碼是：
                </div>
                <div className="text-4xl font-bold text-blue-600">
                  {gameState.ratingCode}
                </div>
                <div className="text-lg text-gray-600 mt-2">
                  得分: {gameState.score}
                </div>
              </div>
            )}
            <div className="bg-gray-100 rounded-lg p-4 mb-4">
              <div className="mb-2">本輪耗時: <span className="font-bold text-green-600">{100 - gameState.attemptTime} 秒</span></div>
              <div className="mb-2">總步數: <span className="font-bold text-green-600">{gameState.steps}</span></div>
              <div className="mb-2">總耗時: <span className="font-bold text-green-600">{300 - gameState.gameTime} 秒</span></div>
              <div>失敗次數: <span className="font-bold text-green-600">{gameState.failCount}</span></div>
            </div>
            <button 
              onClick={restartGame}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {gameState.isGameOver ? '重新開始遊戲' : '再次挑戰'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RiverCrossingGame;