import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TimestampConverter = () => {
  const [timestamp, setTimestamp] = useState('');
  const [date, setDate] = useState('');
  const [result, setResult] = useState('');

  const convertTimestamp = () => {
    if (timestamp) {
      const date = new Date(parseInt(timestamp) * 1000);
      setResult(date.toLocaleString());
    }
  };

  const convertDate = () => {
    if (date) {
      const timestamp = Math.floor(new Date(date).getTime() / 1000);
      setResult(timestamp.toString());
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">時間戳記轉換工具</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
        <div className="border rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold mb-2">Unix 時間戳轉日期</h2>
          <input
            type="number"
            placeholder="輸入 Unix 時間戳"
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          />
          <button onClick={convertTimestamp} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            轉換
          </button>
        </div>
        <div className="border rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold mb-2">日期轉 Unix 時間戳</h2>
          <input
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          />
          <button onClick={convertDate} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            轉換
          </button>
        </div>
      </div>
      {result && (
        <div className="mt-6 p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">轉換結果</h2>
          <p className="text-lg">{result}</p>
        </div>
      )}
    </div>
  );
};

export default TimestampConverter;