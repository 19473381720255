import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { diffWords } from 'diff';

const DiffChecker = () => {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [diff, setDiff] = useState([]);

  const compareTexts = () => {
    const differences = diffWords(text1, text2);
    setDiff(differences);
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">文本差異比較工具</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <textarea
          className="w-full h-64 p-2 border rounded"
          value={text1}
          onChange={(e) => setText1(e.target.value)}
          placeholder="輸入第一段文本..."
        ></textarea>
        <textarea
          className="w-full h-64 p-2 border rounded"
          value={text2}
          onChange={(e) => setText2(e.target.value)}
          placeholder="輸入第二段文本..."
        ></textarea>
      </div>
      <button 
        onClick={compareTexts}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        比較文本
      </button>
      {diff.length > 0 && (
        <div className="mt-4 p-4 border rounded">
          {diff.map((part, index) => (
            <span 
              key={index}
              className={part.added ? 'bg-green-200' : part.removed ? 'bg-red-200' : ''}
            >
              {part.value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default DiffChecker;