import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const OrderChecker = () => {
  const [network, setNetwork] = useState('BASE');
  const [contractAddress, setContractAddress] = useState('0xa47172bea713e21f6bbdd8c18f1f4db3ff064a9f');
  const [listenObject, setListenObject] = useState('');
  const [orders, setOrders] = useState([]);

  const networks = ['BASE', 'ETH', 'BNB', 'Polygon', 'Mantle'];

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://v3-api.lootex.io/api/v3/explore/assets', {
        params: {
          limit: 50,
          'collectionSlugs[0]': `${network.toLowerCase()}:${contractAddress}`,
          sortBy: 'bestListPrice',
          chainId: 8453, // 这里需要根据选择的网络动态设置
          isCount: true,
          page: 1
        }
      });

      const listenAddresses = listenObject.split('\n').map(address => address.trim().toLowerCase());
      const newOrders = response.data.items
        .filter(item => item.order && item.order.listing)
        .map(item => ({
          offerer: item.order.listing.offerer,
          price: item.order.listing.price,
          priceSymbol: item.order.listing.priceSymbol,
          startTime: item.order.listing.startTime,
          endTime: item.order.listing.endTime,
          isBot: listenAddresses.includes(item.order.listing.offerer.toLowerCase())
        }));

      setOrders(newOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">订单检查器</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div>
          <label className="block mb-2">网络</label>
          <select
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {networks.map(net => (
              <option key={net} value={net}>{net}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">合约地址</label>
          <input
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block mb-2">监听对象（每行一个地址）</label>
          <textarea
            value={listenObject}
            onChange={(e) => setListenObject(e.target.value)}
            className="w-full h-32 p-2 border rounded"
            placeholder="输入要监听的地址，每行一个..."
          ></textarea>
        </div>
      </div>
      <button 
        onClick={fetchOrders}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        检查订单
      </button>
      {orders.length > 0 && (
        <div className="mt-4 overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 border">Offerer</th>
                <th className="px-4 py-2 border">Price</th>
                <th className="px-4 py-2 border">Symbol</th>
                <th className="px-4 py-2 border">Start Time</th>
                <th className="px-4 py-2 border">End Time</th>
                <th className="px-4 py-2 border">Is Bot</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border">{order.offerer}</td>
                  <td className="px-4 py-2 border">{order.price}</td>
                  <td className="px-4 py-2 border">{order.priceSymbol}</td>
                  <td className="px-4 py-2 border">{new Date(order.startTime).toLocaleString()}</td>
                  <td className="px-4 py-2 border">{new Date(order.endTime).toLocaleString()}</td>
                  <td className="px-4 py-2 border">{order.isBot ? '是' : '否'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrderChecker;
