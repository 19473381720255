import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import TimestampConverter from './components/TimestampConverter';
import ConvertTool from './components/ConvertTool';
import JsonChecker from './components/JsonChecker';
import DiffChecker from './components/DiffChecker';
import TxDecoder from './components/TxDecoder';
import RiverCrossingGame from './components/RiverCrossingGame';
import BlockchainCaller from './components/BlockchainCaller';
import HanoiTower from './components/HanoiTower';
import OrderChecker from './components/OrderChecker'; // 新增这行

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/timestamp" element={<TimestampConverter />} />
        <Route path="/convert-tool" element={<ConvertTool />} />
        <Route path="/json-checker" element={<JsonChecker />} />
        <Route path="/diff-checker" element={<DiffChecker />} />
        <Route path="/tx-decoder" element={<TxDecoder />} />
        <Route path="/river-crossing-game" element={<RiverCrossingGame />} />
        <Route path="/blockchain-caller" element={<BlockchainCaller />} />
        <Route path="/hanoi-tower" element={<HanoiTower />} />
        <Route path="/order-checker" element={<OrderChecker />} /> {/* 新增这行 */}
      </Routes>
    </Router>
  );
}

export default App;