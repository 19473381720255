import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ethers } from 'ethers';

const TxDecoder = () => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  const apiKey = process.env.REACT_APP_BASESCAN_API_KEY;

  const decodeTransaction = async () => {
    setError('');
    setResult(null);

    if (!apiKey) {
      setError('API key is not set. Please check your environment variables.');
      return;
    }

    if (input.startsWith('http')) {
      try {
        const txHash = input.split('/').pop();
        const url = `https://api.basescan.org/api?module=proxy&action=eth_getTransactionByHash&txhash=${txHash}&apikey=${apiKey}`;
        console.log('API URL:', url); // 用於調試
        const response = await axios.get(url);
        
        console.log('API Response:', response.data);

        const tx = response.data.result;

        if (!tx) {
          throw new Error('Transaction not found');
        }

        const decodedResult = {
          hash: tx.hash,
          from: tx.from,
          to: tx.to,
          value: tx.value ? ethers.formatEther(tx.value) + ' ETH' : '0 ETH',
          gas: parseInt(tx.gas, 16).toString(),
          gasPrice: ethers.formatUnits(tx.gasPrice, 'gwei') + ' Gwei',
          maxFeePerGas: tx.maxFeePerGas ? ethers.formatUnits(tx.maxFeePerGas, 'gwei') + ' Gwei' : 'N/A',
          maxPriorityFeePerGas: tx.maxPriorityFeePerGas ? ethers.formatUnits(tx.maxPriorityFeePerGas, 'gwei') + ' Gwei' : 'N/A',
          nonce: parseInt(tx.nonce, 16).toString(),
          blockNumber: parseInt(tx.blockNumber, 16).toString(),
          blockHash: tx.blockHash,
          transactionIndex: parseInt(tx.transactionIndex, 16).toString(),
          chainId: parseInt(tx.chainId, 16).toString(),
          type: parseInt(tx.type, 16).toString(),
          inputData: await decodeInputData(tx.input)
        };

        setResult(decodedResult);
      } catch (err) {
        console.error('API Error:', err);
        setError('Error fetching transaction data: ' + (err.response?.data?.message || err.message));
      }
    } else {
      // 如果輸入不是 URL，假設它是直接的 input data
      try {
        const decodedInputData = await decodeInputData(input);
        setResult({ inputData: decodedInputData });
      } catch (err) {
        console.error('Decoding Error:', err);
        setError('Error decoding input data: ' + err.message);
      }
    }
  };

  const decodeInputData = async (inputData) => {
    if (!inputData || inputData === '0x') return 'No input data';
    
    const methodId = inputData.slice(0, 10);
    const params = inputData.slice(10);

    const commonMethods = {
      '0x4c674c2d': 'createPairedBundleAndAddItem',
      // 添加更多已知的方法 ID
    };

    return {
      methodId: methodId,
      methodName: commonMethods[methodId] || 'Unknown Method',
      params: params
    };
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">TX Hash 解析工具</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="mt-4">
        <textarea
          className="w-full h-32 p-2 border rounded"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="輸入交易 URL 或 input data..."
        ></textarea>
        <button 
          onClick={decodeTransaction}
          className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          解析
        </button>
        {error && <div className="mt-4 p-4 bg-red-100 rounded">{error}</div>}
        {result && (
          <div className="mt-4 p-4 bg-gray-100 rounded">
            <pre>{JSON.stringify(result, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default TxDecoder;